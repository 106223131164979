const CountdownLocalized = (() => {
    const countdown = () => {
        const $counter = $('#counter');
        const $counterwrap = $('#counterwrap');

        if ($counter.length) {
            // Get the date from Page Designer
            const endDate = $counter.attr('data-date');

            // Set the date we're counting down to
            const countDownDate = new Date(endDate).getTime();

            // Update the count down every 1 second
            const x = setInterval(() => {
                // Get today's date and time
                const now = new Date().getTime();

                // Find the distance between now and the count down date
                const distance = countDownDate - now;

                // Get counter days HTML element
                const $daysElement = $('.js-counter-days-value');
                const $daysLabelElement = $('.js-counter-days-label');

                // Time calculations for days, hours, minutes and seconds
                const days = $daysElement.length ? Math.floor(distance / (1000 * 60 * 60 * 24)) : 0;
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                if (days <= 0 && $daysElement.length && $daysLabelElement.length) {
                    $daysElement.remove();
                    $daysLabelElement.remove();
                }

                if ($daysElement.length && days) {
                    $daysElement.text(days);
                }
                $('.js-counter-hours-value').text(hours);
                $('.js-counter-mins-value').text(minutes);
                $('.js-counter-secs-value').text(seconds);

                // If the count down is over, write some text
                if (distance < 0) {
                    clearInterval(x);
                    $counterwrap.addClass('d-none');
                }
            }, 10);
        }
    };

    /**
     * @description Function for handling black friday banner CTA click
     */
    function handleCountdownLocalizedLinkClick() {
        $('.js-countdownLocalized__link').on('click', function (e) {
            const hrefUrl = $(e.currentTarget).data('href-url');
            const isUrlToCheckout = $(e.currentTarget).hasClass('js-countdownLocalized__link-checkout');
            if (isUrlToCheckout) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    ecommerce: null
                });
                window.dataLayer.push({
                    event: 'precheckout',
                    step: '1',
                    component_name: 'become a member',
                });
            }
            window.location.href = hrefUrl;
        });
    }

    return {
        init() {
            countdown();
            handleCountdownLocalizedLinkClick();
        },
    };
})();

export default (() => {
    $(document).ready(CountdownLocalized.init);
})();
